import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ViewGeneratorStore from "../../ViewGenerator/ViewGeneratorStore";
import { groupBy, isEmpty, keys, lowerFirst, slice, take, uniqBy, wrap } from "lodash";
import { Col, Input, InputNumber, Row, Select, Space, Spin, Table } from "antd";
import ViewGeneratorButtonsRenderer from "../../ViewGenerator/components/ViewGeneratorButtonsRenderer";
import GraphQlService from "../GraphQlService";
import { graphql } from "graphql";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import DataGrid, { Column, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { CellRender } from "../../ViewGenerator/components/DataGrid/common/ViewGeneratorDevExpressRender";

const renderColumns = columnsMap => {
  let columnsReturn = [];

  // add columns to the columnsReturn array
  columnsMap.forEach(column => {
    if (column.children) {
      columnsReturn.push(
        <Column key={column.key} caption={column.caption}>
          {renderColumns(column.children)}
        </Column>
      );
    } else {
      const { children, dataIndex, title, ...rest } = column;
      columnsReturn.push(<Column {...rest} />);
    }
  });

  return columnsReturn;
};

const generateCaption = container => {
  const paylaod = JSON.parse(container.payload || "{}");
  return (
    <>
      <Row>
        <Col>Subcontrata:</Col>
        <Col>{container.name}</Col>
      </Row>
      <Row>
        <Col>CIF:</Col>
        <Col>{paylaod["cif"]}</Col>
      </Row>
    </>
  );
};

const CompareAssetsPage = props => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const containerIds = queryParams.get("containerIds")?.split(",") || [];
  const relatedAssetDefinitionId = queryParams.get("relatedAssetDefinitionId");
  const assetDefinitionId = queryParams.get("assetDefinitionId");
  const [coeficiency, setCoeficiency] = useState(15);
  const [totalColumns, setTotalColumns] = useState([]);

  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const graphQlService = new GraphQlService();

  const onExporting = e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
      });
    });
  };

  useEffect(() => {
    if (!containerIds) {
      generateData(
        ViewGeneratorStore.data["containers"]
          .map(c => ({ ...c, payload: JSON.parse(c.payload || "{}") }))
          .filter(x => ViewGeneratorStore.selectedContainerIds.includes(x.rootId)),
        ViewGeneratorStore.hubConfig,
        ViewGeneratorStore.assetDefinition
      );
    } else loadRelatedData();
  }, []);

  const updateCoefficiency = value => {
    setCoeficiency(value);
  };

  const generateData = (containers, hubConfig, assetDef) => {
    console.log("generate data", toJS(containers), toJS(hubConfig), toJS(assetDef));

    var preColumns = [{ key: "field", dataIndex: "field", render: text => <b>{text}</b> }];
    containers.forEach((sd, i) => preColumns.push({ key: sd.id, dataIndex: `column${i}` }));
    setColumns(preColumns);

    var preDataSource = [];

    const f = keys(hubConfig.fields)
      .filter(x => hubConfig.fields[x].tableVisible === true)
      .map(f => assetDef.fields.find(x => x.id === f));

    f.forEach(f => preDataSource.push({ field: f.label, dbTableLocation: f.dbTableLocation }));

    preDataSource.forEach(ds => {
      slice(preColumns, 1, preColumns.length).forEach(c => {
        var data = containers.find(x => x.id === c.key);

        if (ds.dbTableLocation.includes("Payload$"))
          ds[c.dataIndex] = data.payload[ds.dbTableLocation.replace("Payload$", "")];
        else ds[c.dataIndex] = data[lowerFirst(ds.dbTableLocation)];
      });
    });

    var footer = { field: "" };
    containers.forEach((sd, i) => {
      footer[`column${i}`] = (
        <ViewGeneratorButtonsRenderer
          buttons={hubConfig.tableRowButtons}
          record={sd}
          displayOption={{ icon: true, tooltip: true, text: false }}
        />
      );
    });

    preDataSource.push(footer);

    setDataSource(preDataSource);
  };

  const loadRelatedData = async () => {
    // linkConfig = relation asset definition => presupesto 2 oferta
    // assetDefinition = relation asset definition => paquete 2 oferta

    const assetDefinitionFetch = await graphQlService.get(
      `{ assetsDefinitionById(id: "${relatedAssetDefinitionId}"){id
    value
    isVirtual
    isEvidence
    fields {
      id
      symbol
      value
      type
      dbTableLocation
      label}}}`
    );

    const assetDefinition = assetDefinitionFetch?.data?.assetsDefinitionById;
    const hubDataResponse = await graphQlService.get(`{ uiHub(id: "${relatedAssetDefinitionId}") { payload } }`);
    var hubConfig = JSON.parse(hubDataResponse.data["uiHub"].payload || "{}");
    // hubConfig = hub config of oferta

    // = = = Generate columns for "cost items"
    var newColumns = [];
    // keys(hubConfig.fields).forEach((hcf, i) => {
    //   var assetDef = linkConfig.fields.find(x => x.id === hcf)
    //   if (assetDef != null) {
    //     newColumns.push({
    //       title: assetDef.label,
    //       dataIndex: `column${hcf}`,
    //       key: hcf,
    //       assetDef
    //     });
    //   }
    // })

    const linkConfig = hubConfig?.linksConfig.find(x => x.id == "c888e8f8-4da0-44fa-b429-08dcc01b1cd4"); // WARNING: hardcoded field id

    var costItemDescField = linkConfig.relatedAssetDefinition.fields.find(
      x => x.id === "842e033c-c900-4439-9243-85ba65a9c3e9" // WARNING: hardcoded field id
    );

    // = = = Columns definition
    newColumns.push({
      title: "Codigo",
      caption: "Codigo",
      dataIndex: "Payload$Code",
      dataField: "Payload$Code",
      key: "code",
      width: 80,
      fixed: true
    });

    newColumns.push({
      title: "Ud",
      caption: "Ud",
      dataIndex: "Payload$Ud",
      dataField: "Payload$Ud",
      key: "ud",
      width: 60,
      fixed: true
    });

    newColumns.push({
      title: costItemDescField.label,
      caption: "Concepto", // WARNING: hardcoded field label
      dataIndex: costItemDescField.dbTableLocation,
      dataField: costItemDescField.dbTableLocation,
      key: costItemDescField.id,
      width: 400,
      fixed: true
    });

    newColumns.push({
      title: "Cant. Pres.",
      caption: "Cant. Pres.", // WARNING: hardcoded field label
      dataIndex: "Payload$CanPres",
      dataField: "Payload$CanPres",
      key: "canPres",
      width: 60,
      alignment: "right"
    });

    newColumns.push({
      title: "Coste Previsto",
      caption: "Coste Previsto", // WARNING: hardcoded field label
      key: "costePrevisto",
      children: [
        {
          title: "PVP Pres",
          caption: "PVP Pres", // WARNING: hardcoded field label
          dataIndex: "Payload$PVPPres",
          dataField: "Payload$PVPPres",
          key: "pvpPres",
          cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
          width: 80
        },
        {
          title: "Imp. Pres",
          caption: "Imp. Pres", // WARNING: hardcoded field label
          dataIndex: "Payload$ImpPres",
          dataField: "Payload$ImpPres",
          key: "impPres",
          cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
          width: 100
        }
      ]
    });

    newColumns.push({
      title: "Venta Previsto",
      caption: "Venta Previsto", // WARNING: hardcoded field label
      key: "ventaPrevisto",
      children: [
        {
          title: "PVP Venta",
          caption: "PVP Venta", // WARNING: hardcoded field label
          dataIndex: "Payload$PVPVenta",
          dataField: "Payload$PVPVenta",
          cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
          key: "pvpVenta",
          width: 80
        },
        {
          title: "Imp. Venta",
          caption: "Imp. Venta", // WARNING: hardcoded field label
          dataIndex: "Payload$ImpObj",
          dataField: "Payload$ImpObj",
          key: "impVenta",
          cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
          width: 100
        }
      ]
    });

    // make this column background green
    newColumns.push({
      title: "Cantidad Real",
      caption: "Cant. Real", // WARNING: hardcoded field label
      dataIndex: "Payload$CanReal",
      dataField: "Payload$CanReal",
      key: "canReal",
      width: 60
    });

    newColumns.push({
      title: "Concepto Contratación",
      caption: "Concepto Contratación", // WARNING: hardcoded field label
      dataIndex: "Payload$Nat",
      dataField: "Payload$Nat",
      key: "nat",
      width: 100,
      key: "nat"
    });

    // now we need to add a column that will be called "COEFICIENTE {coeficient}%	REDUCCIÓN	RESPECTO 	COSTE PREVISTO	OBJETIVO" which will have two columns in it, "objectivo unitaria" and "objetivo total", which are calcualated from ImpPres and PVPPres multiplied by the coeficient
    newColumns.push({
      title: `COEFICIENTE ${coeficiency}% REDUCCIÓN`,
      caption: `COEFICIENTE ${coeficiency}% REDUCCIÓN`,
      key: "coeficiente",
      children: [
        {
          title: "Objetivo Unitaria",
          caption: "Objetivo Unitaria",
          dataIndex: "Payload$PVPCoef",
          dataField: "Payload$PVPCoef",
          key: "objUnit",
          cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
          width: 80
        },
        {
          title: "Objetivo Total",
          caption: "Objetivo Total",
          dataIndex: "Payload$ImpCoef",
          dataField: "Payload$ImpCoef",
          key: "objTotal",
          cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
          width: 100,
          alignment: "right"
        }
      ]
    });

    // = = = Generate columns for "offers"
    var triggerDataQuery = [];
    containerIds.forEach(cId => triggerDataQuery.push(`{ path:"RootId", comparison: EQUAL, value: "${cId}" }`));
    var headers = await graphQlService.get(
      `{containers(where:[${triggerDataQuery.join(`,`)}], whereConnector: "OR"){ id rootId name payload }}`
    );

    let totalColumnsArray = ["Payload$ImpObj", "Payload$ImpCoef", "Payload$ImpPres"];
    headers.data.containers.forEach(c => {
      newColumns.push({
        title: c.name,
        caption: c.name,
        key: c.id,
        children: [
          {
            title: "PVP",
            caption: "PVP",
            dataIndex: `${c.id}_Payload$PVP`,
            dataField: `${c.id}_Payload$PVP`,
            key: c.id + "_PVP",
            width: 80,
            cellRender: cellData => <CellRender render={"euro"} cellData={cellData} />,
            render: (value, row, index) => {
              if (!value) return <></>;
              return <>{row[`${c.id}_Payload$PVP`]}</>;
            }
          },
          {
            title: "Total",
            caption: "Total",
            dataIndex: `${c.id}_Payload$ProposedValue`,
            dataField: `${c.id}_Payload$ProposedValue`,
            key: c.id + "_ProposedValue",
            width: 100,
            cellRender: value => {
              console.log('total value? ', toJS(value))
              if (value.value === "button_") {
                return <div style={{ textAlign: 'center' }}>
                  <ViewGeneratorButtonsRenderer
                    buttons={hubConfig.tableRowButtons}
                    record={c}
                    displayOption={{ icon: true, tooltip: true, text: false }}
                  />
                </div>
              }

              if (value?.data[`${c.id}_Payload$ProposedValue`] > value?.data?.Payload$ImpObj)
                return (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    <CellRender render="euro" cellData={{ value: value?.data[`${c.id}_Payload$ProposedValue`] }} />
                  </div>
                );
              return <CellRender render="euro" cellData={{ value: value?.data[`${c.id}_Payload$ProposedValue`] }} />;
            }
          },
        ]
      });

      totalColumnsArray.push(c.id + "_Payload$ProposedValue");
    });

    console.log('columns', newColumns)
    setColumns(newColumns);
    setTotalColumns(totalColumnsArray);
    console.log("columns", newColumns);

    var containersResponses = [];
    for (let i = 0; i < containerIds.length; i++) {
      const containersResponse = (
        await graphQlService.get(
          `{ containerRelationsHub(containerId:"${containerIds[i]}", assetDefinitionId:"${relatedAssetDefinitionId}", relationId:"${linkConfig.id}") { payload relatedContainer { id name payload } } }`
        )
      ).data.containerRelationsHub.filter(x => x.relatedContainer);

      containersResponses = [
        ...containersResponses,
        ...containersResponse.map(x => ({
          ...x,
          containerId: containerIds[i],
          payload: JSON.parse(x.payload || "{}"),
          relatedContainer: {
            ...x.relatedContainer,
            payload: JSON.parse(x.relatedContainer.payload || "{}")
          }
        }))
      ];
    }
    var costItemsGroups = groupBy(containersResponses, x => x.relatedContainer.id);

    var newData = [];

    // = = = here is where we add the hardcoded fields, in this example is valor total value
    /*     var hardcodedFields = ["ef539142-6ea2-44aa-9e43-85bad913b389"];
    hardcodedFields.forEach(fieldId => {
      var field = assetDefinition.fields.find(x => x.id === fieldId);

      var newItem = {};
      newItem[`Payload$Summary`] = field.label;

      headers.data.containers.forEach(c => {
        if (field.dbTableLocation.includes("Payload$")) {
          var payload = JSON.parse(c.payload);
          newItem["key"] = c.id;
          newItem[`${c.rootId}_Payload$ProposedValue`] = payload[field.dbTableLocation.split("$")[1]];
        } else newItem[`${c.rootId}_Payload$ProposedValue`] = c[field.dbTableLocation];
      });

      newData.push(newItem);
    });

    newData.push({ key: "empty" }); */

    keys(costItemsGroups).forEach(costItemsGroupsKey => {
      var groupData = {};

      costItemsGroups[costItemsGroupsKey].forEach(item => {
        groupData["key"] = item.containerId + item.relatedContainer.id;

        keys(item.payload).forEach(payloadKey => {
          groupData[`${item.containerId}_Payload$${payloadKey}`] = item.payload[payloadKey];
        });

        keys(item.relatedContainer.payload).forEach(payloadKey => {
          groupData[`Payload$${payloadKey}`] = item.relatedContainer.payload[payloadKey];
        });

        // add PVPCoef and ImpCoef calculated based on the coeficiency values multipled by PVPObj and ImpObj
        if (item.relatedContainer.payload["PVPVenta"]) {
          groupData[`Payload$PVPCoef`] = (item.relatedContainer.payload["PVPVenta"] * (1 - coeficiency / 100)).toFixed(
            2
          );
        }

        if (item.relatedContainer.payload["ImpObj"]) {
          groupData[`Payload$ImpCoef`] = (item.relatedContainer.payload["ImpObj"] * (1 - coeficiency / 100)).toFixed(2);
        }


      });
      newData.push(groupData);
    });

    var buttonsRow = {};
    containerIds.forEach(cid => (buttonsRow[`${cid}_Payload$ProposedValue`] = `button_`));
    newData.push(buttonsRow);

    console.log("data", newData);
    setDataSource(newData);
    setLoading(false);
  };

  if (loading) return <Spin />;

  const renderedColumns = renderColumns(columns);
  //console.log("renderedColumns", renderedColumns);

  return (
    <>
      {/*       <Row>
        <Col span={4} offset={20}>
          <label>REDUCCIÓN: </label>
          <InputNumber defaultValue={coeficiency} onChange={updateCoefficiency} min={1} max={20} step={1} />
        </Col>
      </Row>
      <br /> */}
      <DataGrid dataSource={dataSource} wordWrapEnabled showBorders>
        {renderedColumns}
        <Summary>
          {totalColumns.map((column, index) => (
            <TotalItem key={index} column={column} summaryType="sum" valueFormat={{ type: "currency", precision: 2, currency: "EUR" }} />
          ))}
        </Summary>
      </DataGrid>
    </>
  );
};

export default withTranslation()(observer(CompareAssetsPage));
