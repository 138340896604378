import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { Popconfirm, Modal, Button, Spin, Upload, Row, Col, Input, Space, Tooltip, Divider, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import AssignedUser from "../../Common/AssignedUser";
import GraphQlService from "../../Common/GraphQlService";
import ViewGeneratorStore from "../ViewGeneratorStore";
import DocumentComments from "../../WorflowRiskControl/Documents/components/comments/DocumentComments";
import DocumentsManagementComponent from "../../components/DocumentsManagementComponent";

import Icon from "@ant-design/icons";
import * as AntdIcons from "@ant-design/icons";
import { themeConfig } from "../../Common/Layout/config";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash";
import { toJS } from "mobx";
import ViewGeneratorDataGridCompareComponent from "./DataGrid/ViewGeneratorDataGridCompareComponent";
import i18next from "i18next";

const ViewGeneratorButtonsRenderer = ({
  buttons,
  t,
  isHeader = false,
  record = undefined,
  displayOption = { tooltip: false, text: true, icon: false },
  onCompareShow
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [buttonInputFileModalVisible, setButtonInputFileModalVisible] = useState();
  const [buttonInputModal, setButtonInputModal] = useState({
    isVisible: false
  });
  const [compareModalVisible, setCompareModalVisible] = useState();

  const [containerIds, setContainerIds] = useState();
  const [tmpFile, setTmpFile] = useState();

  useEffect(() => {
    if (!tmpFile) return;

    sendAutomateRequest(buttonInputFileModalVisible, containerIds, tmpFile?.file, tmpFile?.name);
  }, [tmpFile]);

  const { currentTheme } = themeConfig();
  const history = useHistory();

  const [style, setStyle] = useState({
    size: "small",
    textAlign: "center",
    direction: "horizontal"
  });

  useState(() => {
    setIsLoading(true);
    if (isHeader)
      setStyle({
        size: "default",
        textAlign: "left",
        direction: "vertical"
      });
    setIsLoading(false);
  }, []);

  const renderButtons = button => {
    if (button.action.includes("s_")) return renderSystemButton(button);

    if (button.action.includes("c_")) return renderCollectFormButton(button);

    if (button.action.includes("a_")) return renderAutomateFormButton(button);
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  let uploadProps = { disabled: "true" };
  uploadProps = {
    name: "file",
    multiple: false,
    customRequest: async e => {
      setIsLoading(true);
      setTmpFile({ file: await toBase64(e.file), name: e.file.name });
      setIsLoading(false);
    },
    showUploadList: false
  };

  const renderSystemButton = button => {
    switch (button.action.replace("s_", "")) {
      case "delete":
        return (
          <Popconfirm
            key={"s_delete_" + record?.rootId}
            placement="bottomLeft"
            cancelText={t("documents.cancel")}
            okText={t("documents.okText")}
            onConfirm={() => ViewGeneratorStore.remove(record.id)}
            title={t("documents.deleteConfirm")}
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button
              size={style.size}
              style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
              icon={<Icon component={AntdIcons[button.icon]} />}
              danger
            >
              {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
            </Button>
          </Popconfirm>
        );
      case "delete_inc_relations":
        return (
          <Popconfirm
            key={"s_delete_inc_relations_" + record?.rootId}
            placement="bottomLeft"
            cancelText={t("documents.cancel")}
            okText={t("documents.okText")}
            onConfirm={() => ViewGeneratorStore.removeWithRelations(record.id)}
            title={t("documents.deleteConfirm")}
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button
              size={style.size}
              style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
              icon={<Icon component={AntdIcons[button.icon]} />}
              danger
            >
              {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
            </Button>
          </Popconfirm>
        );
      case "assignOwner":
        return (
          <Button
            size={style.size}
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            key={"s_assignOnwer_" + record?.rootId}
          >
            <AssignedUser
              objectType="Container"
              objectId={record?.rootId}
              placement={isLowRes && menuButtonIsClicked ? "right" : "bottomRight"}
            />
          </Button>
        );
      case "comments":
        return (
          <Button
            key={"s_comments_" + record?.rootId}
            size={style.size}
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            icon={
              <Tooltip title={displayOption.tooltip ? button.name : ""}>
                <Icon component={AntdIcons[button.icon]} />
              </Tooltip>
            }
            onClick={() => {
              const modal = Modal.info();
              modal.update({
                content: <DocumentComments viewingContainerId={record?.rootId} />,
                width: "80%",
                className: "documentsModal",
                destroyOnClose: true,
                style:
                  currentTheme === "dark"
                    ? { backgroundColor: "#141414", border: "1px solid #303030", borderRadius: "4px" }
                    : { backgroundColor: "#FFFFFF", border: "1px solid #FFFFFF", borderRadius: "4px" },
                mask: true,
                maskClosable: true,
                icon: null,
                okText: " ",
                footer: null,
                bodyStyle: {
                  paddingTop: 20,
                  backgroundColor: currentTheme === "dark" ? "#141414" : "#FFFFFF"
                },
                okButtonProps: {
                  type: "link",
                  block: true
                }
              });
            }}
          >
            {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
          </Button>
        );
      case "files":
        return (
          <Button
            key={"s_files_" + record?.rootId}
            size={style.size}
            icon={
              <Tooltip title={displayOption.tooltip ? button.name : ""}>
                <Icon component={AntdIcons[button.icon]} />
              </Tooltip>
            }
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            onClick={() => {
              const modal = Modal.confirm();
              modal.update({
                content: <DocumentsManagementComponent containerId={record?.rootId} currentTheme={currentTheme} />,
                closable: true,
                className: "documentsModal",
                width: "1000px",
                mask: true,
                style:
                  currentTheme === "dark"
                    ? { backgroundColor: "#141414", border: "1px solid #303030", borderRadius: "4px" }
                    : { backgroundColor: "#FFFFFF", border: "1px solid #FFFFFF", borderRadius: "4px" },
                maskClosable: true,
                icon: null,
                okText: " ",
                bodyStyle: {
                  paddingTop: 20,
                  backgroundColor: currentTheme === "dark" ? "#141414" : "#FFFFFF"
                },
                okButtonProps: {
                  type: "link",
                  block: true
                }
              });
            }}
          >
            {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
          </Button>
        );

      case "exportToExcel":
        return (
          <Button
            key={isHeader ? "s_exportToExecel_" : "s_exportToExcel" + record?.rootId}
            size={style.size}
            icon={
              <Tooltip title={displayOption.tooltip ? button.name : ""}>
                <Icon component={AntdIcons[button.icon]} />
              </Tooltip>
            }
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            onClick={() => {
              if (!isNil(ViewGeneratorStore.exportGridCallback)) ViewGeneratorStore.exportGridCallback();
            }}
          >
            {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
          </Button>
        );

      case "expandAll":
        return (
          <Button
            key={isHeader ? "s_expandAll" : "s_expandAll_" + record?.rootId}
            size={style.size}
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            icon={
              <Tooltip title={displayOption.tooltip ? button.name : ""}>
                <Icon component={AntdIcons[button.icon]} />
              </Tooltip>
            }
            onClick={() => {
              if (!isNil(ViewGeneratorStore.expandAllCallback)) ViewGeneratorStore.expandAllCallback();
            }}
          >
            {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
          </Button>
        );
      case "toggleChart":
        return (
          <Button
            key={"s_toggleChart_" + record?.rootId}
            size={style.size}
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            icon={
              <Tooltip title={displayOption.tooltip ? button.name : ""}>
                <Icon component={AntdIcons[button.icon]} />
              </Tooltip>
            }
            onClick={() => {
              if (!isNil(ViewGeneratorStore.toggleChartCallback)) ViewGeneratorStore.toggleChartCallback();
            }}
          >
            {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
          </Button>
        );

      case "collapseAll":
        return (
          <Button
            key={isHeader ? "s_collapseAll" : "s_collapseAll_" + record?.rootId}
            size={style.size}
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            icon={
              <Tooltip title={displayOption.tooltip ? button.name : ""}>
                <Icon component={AntdIcons[button.icon]} />
              </Tooltip>
            }
            onClick={() => {
              if (!isNil(ViewGeneratorStore.expandAllCallback)) ViewGeneratorStore.collpaseAllCallback();
            }}
          >
            {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
          </Button>
        );
    }
  };

  const renderCollectFormButton = button => {
    return (
      <Button
        key={isHeader ? button.action : button.action + "_" + record?.rootId}
        size={style.size}
        style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
        icon={
          <Tooltip title={displayOption.tooltip ? button.name : ""}>
            <Icon component={AntdIcons[button.icon]} />
          </Tooltip>
        }
        onClick={() => {
          const newLocation = isHeader
            ? `/collect/entry/new?l=${
                history.location.pathname
              }&folderId=00000000-0000-0000-0000-000000000000#p=${button.action.replace("c_", "")}`
            : `/collect/entry/hub/${button.action.replace("c_", "")}/${record.rootId}?l=${history.location.pathname}`;

          history.push(newLocation);
        }}
      >
        {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
      </Button>
    );
  };

  const sendAutomateRequest = (id, containerIds, file, filename) => {
    setIsLoading(true);
    const graphQlService = new GraphQlService();
    setContainerIds(containerIds);
    graphQlService
      .post(
        `mutation mutate($automateWorkflowId: Guid, $containerIds: [Guid], $file: String, $filename: String){ hubAutomateTrigger(automateWorkflowId: $automateWorkflowId, containerIds: $containerIds, file: $file, filename: $filename) }`,
        { automateWorkflowId: id, containerIds, file, filename }
      )
      .then(r => {
        if (r.errors) {
          if (r.errors[0].extensions.data.CODE === "MISSING_INPUT_FILE") {
            setButtonInputFileModalVisible(id);
          } else if (r.errors[0].extensions.data.CODE.includes("MISSING_CONTAINER_IDS")) {
            var type = r.errors[0].extensions.data.CODE.replace("MISSING_CONTAINER_IDS||", "");

            console.log("second error?", type);
            if (type === "selected") {
              setContainerIds(ViewGeneratorStore.selectedContainerIds);
              sendAutomateRequest(id, ViewGeneratorStore.selectedContainerIds, null, null);
            } else if (type === "current_results") {
              setContainerIds(ViewGeneratorStore.currentPageContainerIds);
              sendAutomateRequest(
                id,
                ViewGeneratorStore.currentPageContainerIds,
                tmpFileUpload?.file,
                tmpFileUpload?.name
              );
            } else if (type === "single") {
              setContainerIds([record]);
              sendAutomateRequest(id, [record], tmpFileUpload?.file, tmpFileUpload?.name);
            }
          } else if (r.errors[0].extensions.data.CODE.includes("new_collect_entry")) {
            history.push(
              `/${localStorage.getItem(`projectName`)}/collect/entry/${
                r.errors[0].extensions.data.CODE.split("||")[1]
              }?l=${history.location.pathname}`
            );
          } else graphQlService.displayErrors(r.errors);
        } else {
          message.success(i18next.t("automate.executing"));
          setButtonInputModal({ isVisible: false });
          setButtonInputFileModalVisible(undefined);
          setContainerIds(undefined);
          setTmpFile(undefined);
          onCompareShow(true);
        }
        setIsLoading(false);
      });
  };

  const renderAutomateFormButton = button => {
    return (
      <Button
        key={isHeader ? button.action : button.action + "_" + record?.rootId}
        size={style.size}
        style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
        icon={
          <Tooltip title={displayOption.tooltip ? button.name : ""}>
            <Icon component={AntdIcons[button.icon]} />
          </Tooltip>
        }
        onClick={() =>
          sendAutomateRequest(
            button.action.replace("a_", ""),
            record
              ? [record.id]
              : ViewGeneratorStore.selectedContainerIds
              ? ViewGeneratorStore.selectedContainerIds
              : []
          )
        }
      >
        {displayOption.text && <span style={{ padding: 0 }}>{button.name}</span>}
      </Button>
    );
  };

  if (isLoading) return <></>;
  return (
    <>
      <Space.Compact direction={style.direction}>
        {ViewGeneratorStore.hubConfig?.SelectableRows && isHeader && (
          <Button
            size={style.size}
            icon={<AntdIcons.CheckSquareOutlined />}
            style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
            onClick={() => (ViewGeneratorStore.tableRowSelectionEnabled = !ViewGeneratorStore.tableRowSelectionEnabled)}
          >
            <span style={{ padding: 0 }}>{t("hub.toggleSelection")}</span>
          </Button>
        )}

        {ViewGeneratorStore.hubConfig?.SelectableRows &&
          ViewGeneratorStore.hubConfig?.CompareSelected &&
          ViewGeneratorStore.selectedContainerIds.length > 0 &&
          isHeader && (
            <Button
              size={style.size}
              icon={<AntdIcons.DiffOutlined />}
              style={{ textAlign: style.textAlign, justifyContent: style.textAlign }}
              onClick={() => {
                setCompareModalVisible(true);
                onCompareShow(true);
              }}
            >
              <span style={{ padding: 0 }}>{t("hub.compareSelected")}</span>
            </Button>
          )}

        {buttons && buttons.map(button => renderButtons(button))}
      </Space.Compact>

      {buttonInputFileModalVisible && (
        <Modal
          open
          onCancel={() => setButtonInputFileModalVisible(undefined)}
          maskClosable
          title={t("DataGrid.SelectInputFile")}
        >
          <Spin spinning={isLoading}>
            <Upload.Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <AntdIcons.InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("hub.uploadPrompt")}</p>
            </Upload.Dragger>
          </Spin>
        </Modal>
      )}

      {buttonInputModal && buttonInputModal.isVisible && (
        <Modal
          open
          onCancel={() => setButtonInputModal({ isVisible: false })}
          maskClosable
          title="Fill in fields"
          onOk={() => sendRequest(buttonInputModal.automateId, null, null, buttonInputModal.selectedContainerIds)}
        >
          <Spin spinning={isLoading}>
            <Row gutter={6}>
              <Col span={8}>Paquete:</Col>
              <Col span={16}>
                <Input style={{ width: "100%" }} />
              </Col>
            </Row>
          </Spin>
        </Modal>
      )}

      {compareModalVisible && (
        <Modal
          open
          maskClosable={false}
          title={t("DataGrid.CompareData")}
          footer={null}
          onCancel={() => setCompareModalVisible(undefined)}
          width={"90%"}
          closable
        >
          <Divider />
          <ViewGeneratorDataGridCompareComponent />
        </Modal>
      )}
    </>
  );
};

export default withTranslation()(observer(ViewGeneratorButtonsRenderer));
